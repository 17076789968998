import React from "react";

function ServiceDrop() {
  return (
    <section>
      <div className="service-contact">
        <h1 className=" has-text-centered">Get In Touch.</h1>
        <h2 className=" has-text-centered">Venussoftwareco@gmail.com</h2>
      </div>
    </section>
  );
}

export default ServiceDrop;
