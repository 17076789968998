import React from "react";

function Glitch() {
  return (
    <section className="section is-mobile">
      <h1 className="glitch has-text-centered is-white is-mobile">Venus</h1>
      <h3 className=" boutique has-text-centered is-mobile">
        A Boutique Software Development Agency
      </h3>
    </section>
  );
}

export default Glitch;
